<template>
    <div>
        <v-row>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="scannedData"            
                    placeholder="สแกนบาร์โค้ด ที่นี่"
                    :prepend-inner-icon="icons.mdiBarcodeScan"
                    @keydown.enter="searchProductByBarCode"
                    outlined
                    dense
                    v-if="!disabled"
                ></v-text-field>
            </v-col>
        </v-row>        
        <v-simple-table v-if="$root.getWindowWidth() > 900">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-center" style="width: 50px;">
                            #
                        </th>                        
                        <th class="text-center" style="min-width: 100px">
                            รูป
                        </th>
                        <th class="text-center" style="width: 100%;">
                            สินค้า
                        </th>                        
                        <th class="text-center" style="min-width: 200px">
                            จำนวนย้าย
                        </th>
                        <th class="text-center" style="min-width: 200px">
                            จำนวนรับ
                        </th>
                    </tr>
                </thead>
                <tbody>                  
                    <tr v-for="(item, index) in $attrs.value.toOrderItems" :key="item.id + '_' + index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        <light-box v-if="!!item.imageURL"
                        :src="item.imageURL"
                        max-width="100"
                        max-height="100"
                        ></light-box>
                        <product-image v-else :image="null" />
                      </td>
                      <td style="border-bottom: none">
                          {{ item.name }}
                          <v-textarea
                            v-if="item.showNote"
                            v-model="item.note"
                            outlined
                            :disabled="disabled"
                          ></v-textarea>
                          <div 
                            class="text--red text-caption" 
                            style="cursor: pointer"
                            v-else
                            @click="item.showNote = true"
                          ><v-icon>{{ icons.mdiPlaylistEdit }}</v-icon> หมายเหตุ</div>                          
                      </td>
                      <td style="border-bottom: none">
                        <v-text-field
                            class="input-align-right my-auto"                            
                            outlined
                            dense
                            v-model.number="item.expectedQuantity"                            
                            :disabled="true"
                        ></v-text-field>
                      </td>
                      <td style="border-bottom: none">
                        <v-text-field
                            class="input-align-right my-auto"                            
                            outlined
                            dense
                            v-model.number="item.quantity"
                            :rules="[validators.required, validators.minValueValidator(item.quantity, 0), validators.integerValidator]"
                            :disabled="disabled"
                        ></v-text-field>
                      </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <div class="product-input-mobile" v-else>
          <div class="title text-center">รายการสินค้า</div>
          <div class="product-item" v-for="(item, index) in $attrs.value.toOrderItems" :key="item.id + '_' + index">                           
            <div class="product-item-tr">
              <div class="product-item-image">
                <light-box v-if="!!item.imageURL"
                :src="item.imageURL"
                max-width="100"
                max-height="100"
                ></light-box>
                <product-image v-else :image="null" />
              </div>
              <div class="product-item-name">
                {{ item.name }}
                <v-textarea
                  v-if="item.showNote"
                  v-model="item.note"
                  outlined
                  :disabled="disabled"
                ></v-textarea>
                <div 
                  class="text--red text-caption" 
                  style="cursor: pointer"
                  v-else
                  @click="item.showNote = true"
                ><v-icon>{{ icons.mdiPlaylistEdit }}</v-icon> หมายเหตุ</div>
                <div class="pb-6" style="border-bottom: none">
                  <v-btn icon @click="deleteItem(index)" v-if="!disabled">
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="product-item-tr">
              <div class="product-item-header py-2">จำนวนย้าย</div>
              <div class="product-item-content">
                <v-text-field
                  class="input-align-right my-auto"                  
                  outlined
                  dense
                  v-model.number="item.expectedQuantity"                  
                  :disabled="true"
                ></v-text-field>
              </div>
            </div>
            <div class="product-item-tr">
              <div class="product-item-header py-2">จำนวนรับ</div>
              <div class="product-item-content">
                <v-text-field
                  class="input-align-right my-auto"                  
                  outlined
                  dense
                  v-model.number="item.quantity"
                  :rules="[validators.required, validators.minValueValidator(item.quantity, 0), validators.integerValidator]"
                  :disabled="disabled"
                ></v-text-field>
              </div>
            </div>
            <v-divider class="py-2"></v-divider>
          </div>
        </div>
    </div>    
</template>
<script>
import ToogleTextField from '@/components/ToogleTextField.vue'
import { asyncGet } from '@/helpers/asyncAxios'
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
} from '@core/utils/validation'
import { mdiBarcodeScan, mdiDeleteOutline, mdiPlaylistEdit } from '@mdi/js'
import LightBox from '../LightBox.vue'
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    warehouse_id: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scannedData: null,
      icons: {
        mdiBarcodeScan,
        mdiPlaylistEdit,
        mdiDeleteOutline,
      },
      scannedCache: [],
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
      },
    }
  },
  watch: {},
  methods: {
    addItem({ id, name, images, sku, barCode, unit }) {
      const order = this.$attrs.value
      const currentItems = order.toOrderItems

      const foudItem = currentItems.find(data => data.id == id)

      if (!foudItem) {
        this.$notify({
          group: 'scannedResult',
          title: 'ผลการสแกน ' + this.scannedData,
          type: 'error',
          text: 'ไม่พบ ' + name + ' ในรายการย้าย',
        })

        return
      } else {
        foudItem.quantity++
      }

      this.$emit('input', order)

      this.$notify({
        group: 'scannedResult',
        title: 'ผลการสแกน ' + this.scannedData,
        type: 'info',
        text: 'เพิ่ม ' + name + ' ในบิลสำเร็จ',
      })
    },
    async searchProductByBarCode() {
      this.isLoading = true
      try {
        const searchData = this.scannedCache.find(data => data.barCode == this.scannedData)

        if (!searchData) {
          const response = await asyncGet(this.url + '/' + this.scannedData + '/barcode', {
            warehouse_id: this.warehouse_id,
          })

          this.scannedCache.push(response)

          this.addItem(response)
        } else {
          this.addItem(searchData)
        }
      } catch (error) {
        this.$notify({
          group: 'scannedResult',
          title: 'ผลการสแกน ' + this.scannedData,
          type: 'error',
          text: error,
        })
      }

      this.scannedData = ''
    },
    filterItem(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.sku && item.sku.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1) ||
        (item.barCode && item.barCode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    },
    getDiscountRules(discount_value, discount_type) {
      return discount_type == 'percent'
        ? [minValueValidator(discount_value, 0), maxValueValidator(discount_value, 100)]
        : [minValueValidator(discount_value, 0)]
    },
  },
  components: {
    ToogleTextField,
    LightBox,
  },
}
</script>
<style scoped>
.product-input-mobile {
  display: flex;
  flex-direction: column;
}

.product-input-mobile > .title {
  background-color: gray;
  color: white;
}

.product-input-mobile > .product-item {
  display: flex;
  flex-direction: column;
}

.product-input-mobile > .product-item > .product-item-tr {
  display: inline-flex;
  flex-direction: row;
  flex-grow: 1;
}

.product-input-mobile > .product-item > .product-item-tr > .product-item-image {
  max-width: 100px;
  width: 100px;
  height: 100px;
}

.product-input-mobile > .product-item > .product-item-tr > .product-item-header {
  width: 100px;
  min-width: 100px;
}

.product-input-mobile > .product-item > .product-item-tr > .product-item-content {
  width: 100%;
}
</style>