<template>
    <div>
        <h2><router-link :to="{ name: 'ivm' }">โอนย้ายสินค้า</router-link> > รับของ {{ order.orderNumber }}</h2>
        <v-row class="mt-4">
          <v-col>
            <v-card>
              <v-form v-model="isFormValid">
                <v-container fluid>                   
                  <v-row>
                    <v-col cols="12" md="6">
                      <delay-autocomplete
                        outlined
                        hide-selected
                        cache-items
                        v-model="order.warehouse_from"
                        label="ย้ายจาก"
                        placeholder="พิมพ์เพื่อค้นหาคลัง (เว้นว่างหากไม่เลือก)"
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/inventory/ivm/warehouseList'"
                        dense
                        return-object                        
                        item-text="name"
                        item-value="id"
                        :disabled="true"
                        hide-details="auto"
                      ></delay-autocomplete>
                    </v-col>                    
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <delay-autocomplete
                        outlined
                        hide-selected
                        cache-items
                        v-model="order.warehouse_to"
                        label="ย้ายไป"
                        placeholder="พิมพ์เพื่อค้นหาคลัง (เว้นว่างหากไม่เลือก)"
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/inventory/ivm/warehouseList'"
                        dense
                        return-object                        
                        item-text="name"
                        item-value="id"
                        :disabled="true"
                        hide-details="auto"
                      ></delay-autocomplete>
                    </v-col>                    
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row>
                  <v-row>
                    <v-col>
                      <IVMRECVProductInput
                      v-model="order"
                      :url="'/inventory/ivm'"
                      :warehouse_id="order.warehouseFromId"
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      ></IVMRECVProductInput>
                    </v-col>              
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row>                  
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-textarea
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.note"
                        label="หมายเหตุ"
                        dense
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>

              <v-card-actions>
                <v-btn
                color="primary"               
                large          
                :disabled="!isFormValid || order.fromOrderItems.length == 0"
                :loading="sendingData"
                @click="submitForm(false)"
                v-if="$store.getters['app/GET_APP_MODE'] != 'read' && order.status != 'cancelled'"
                >
                รับของ
                </v-btn>

                <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.push({ name: 'ivm' })"
                >
                กลับ
                </v-btn>       
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
    </div>
</template>
<script>
import { IVMRECVOrder } from '@/classes/order/IVMRECVOrder'
import DelayCombobox from '@/components/DelayCombobox.vue'
import LightBox from '@/components/LightBox.vue'
import IVMRECVProductInput from '@/components/order/IVMRECVProductInput.vue'
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
} from '@core/utils/validation'
import { mdiCalendar } from '@mdi/js'
export default {
  data() {
    return {
      orderNumber: null,
      isFormValid: false,
      sendingData: false,
      order: null,
      salechannel: null,
      warehouse: null,
      currentStatus: null,
      menu: false,
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
      },
      icons: {
        mdiCalendar,
      },
    }
  },
  async created() {
    const ivm_id = this.$route.params.ivm_id

    this.$store.commit('app/SET_APP_MODE', 'create')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/inventory/ivm/' + ivm_id)

      this.order = new IVMRECVOrder(response)

      if (this.order.status != 'new') this.$store.commit('app/SET_APP_MODE', 'read')
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
    this.currentStatus = this.order.status
  },
  methods: {
    async submitForm(is_draft = false) {
      const unmatchList = this.order.unmatchList

      if (!!unmatchList && unmatchList.length > 0) {
        const unmatchListHTML = unmatchList.join('<br>')

        const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
          title: 'รับของที่ไม่ตรงกับจำนวน ?',
          body:
            'รายการที่ไม่ตรงมีดังนี้' +
            '<br>' +
            '<br>' +
            unmatchListHTML +
            '<br>' +
            '<br>' +
            'คุณยังยืนยันที่จะรับหรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถแก้ไขสถานะกลับคืนมาได้อีก',
        })

        if (!dialogResult) return
      }

      this.sendingData = true
      try {
        const sendData = {
          ...this.order.serialize(),
        }

        const ivm_id = this.$route.params.ivm_id

        await asyncPostAsJson('/inventory/ivm/' + ivm_id + '/receive', sendData)

        this.$router.push({ name: 'ivm' })
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
  },
  components: {
    DelayCombobox,
    LightBox,
    IVMRECVProductInput,
  },
}
</script>,