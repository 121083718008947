import { IVMOrderItem } from "./IVMOrderItem";

export class IVMRECVOrderItem extends IVMOrderItem
{
    #expectedQuantity = 0;

    get expectedQuantity() {
        return this.#expectedQuantity
    }

    constructor(params) {
        super(params)

        const { expectedQuantity } = params

        this.#expectedQuantity = expectedQuantity
    }
}