import { dateObjectToStandardAooFormat } from "@/helpers/dateTimeConverter"
import { IVMOrderItem } from "./IVMOrderItem"

export class IVMOrder
{
    #orderNumber
    #ivmrecvNumber
    #creator    
    #status
    #created_at
    #updated_at

    fromOrderItems = []    
    warehouse_from
    warehouse_to
    note

    get warehouseFrom() {
        return !!this.warehouse_from ? this.warehouse_from.name : '-';
    }

    get warehouseFromId() {
        return !!this.warehouse_from ? this.warehouse_from.id : null;
    }

    get warehouseTo() {
        return !!this.warehouse_to ? this.warehouse_to.name : '-';
    }

    get warehouseToId() {
        return !!this.warehouse_to ? this.warehouse_to.id : null;
    }

    get orderNumber() {
        return this.#orderNumber
    }

    get ivmrecvNumber() {
        return this.#ivmrecvNumber
    }

    get creator() {
        return !!this.#creator ? this.#creator.name + ' ' + this.#creator.lastname : '-'
    }    

    get status() {
        return this.#status
    }

    get createdAt() {
        if (!this.#created_at) return '-'

        return dateObjectToStandardAooFormat(new Date(this.#created_at))
    }

    get updatedAt() {
        if (!this.#created_at) return '-'

        return dateObjectToStandardAooFormat(new Date(this.#updated_at))
    }

    constructor({ warehouse_from, warehouse_to, orderNumber, ivmrecvNumber, note, creator, status, created_at, updated_at, products }) {

        this.#orderNumber = orderNumber
        this.#ivmrecvNumber = ivmrecvNumber
        this.#creator = creator        
        this.#status = status

        this.#created_at = created_at
        this.#updated_at = updated_at

        this.warehouse_from = warehouse_from
        this.warehouse_to = warehouse_to
        this.note = note
        
        if (!!products) {
            const fromOrderItems = this.fromOrderItems

            products.forEach(item => {                
                const orderItem = new IVMOrderItem({
                    ...item,
                    quantity: item.pivot.quantity,
                    note: item.pivot.note
                })

                fromOrderItems.push(orderItem)
            })
        }
    }

    addOrderItem(orderItem) {
        if (!(orderItem instanceof IVMOrderItem)) throw "can add only IVMOrderItem Class"
        
        this.fromOrderItems.push(orderItem)
    }    

    serialize() {
        return {
            items: this.fromOrderItems.map(data => {
                return {
                  id: data.id,
                  quantity: data.quantity,
                  note: data.note,                  
                }
            }),                        
            note: this.note,                                    
            warehouse_from: this.warehouseFromId,
            warehouse_to: this.warehouseToId,
        }
    }
}