import { IVMOrder } from "./IVMOrder";
import { IVMRECVOrderItem } from "./IVMRECVOrderItem";

export class IVMRECVOrder extends IVMOrder
{
    #ivmrecvNumber
    #receiver    
    toOrderItems = []

    get ivmrecvNumber() {
        return this.#ivmrecvNumber
    }

    get receiver() {
        return !!this.#receiver ? this.#receiver.name + ' ' + this.#receiver.lastname : '-'
    }

    get unmatchList() {
        return this.toOrderItems.filter(item => item.expectedQuantity != item.quantity).map(item => item.name + " ( ต้องการ: " + item.expectedQuantity + ", รับจริง: " + item.quantity + " )")
    }

    constructor(params) {

        super(params)

        const { ivmrecvNumber, receiver, products, recv_products } = params        
        
        this.#ivmrecvNumber = ivmrecvNumber
        this.#receiver = receiver

        const toOrderItems = this.toOrderItems
        const fromOrderItems = this.fromOrderItems

        if (!!products && (!recv_products || recv_products.length == 0)) {            
            fromOrderItems.forEach(item => {                
                const orderItem = new IVMRECVOrderItem({
                    ...item.serialize(),
                    expectedQuantity: item.quantity,
                    quantity: 0,
                    note: item.note
                })                

                toOrderItems.push(orderItem)
            })            
        } else if (!!recv_products) {            
            recv_products.forEach(item => {
                const found = fromOrderItems.find(element => item.id == element.id)
                let expectedQuantity = 0;

                if (found) expectedQuantity = found.quantity                

                const orderItem = new IVMRECVOrderItem({
                    ...item,
                    expectedQuantity: expectedQuantity,
                    quantity: item.pivot.quantity,
                    note: item.pivot.note
                })

                toOrderItems.push(orderItem)
            })
        }
    }

    addRecvItem(orderItem) {
        if (!(orderItem instanceof IVMRECVOrderItem)) throw "can add only IVMRECVOrderItem Class"
        
        this.toOrderItems.push(orderItem)
    }    

    serialize() {
        return {
            items: this.toOrderItems.map(data => {
                return {
                  id: data.id,
                  quantity: data.quantity,
                  note: data.note,                  
                }
            }),                        
            note: this.note,                                    
        }
    }
}